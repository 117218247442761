import React from "react"
import Layout from "../components/layout"
import {
  Box,
  Typography,
  Container,
  Grid,
  Divider,
  Paper
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import EstabilidadImg from "../../static/Imagen/estabilidad.png"
import FlexibilidadImg from "../../static/Imagen/flexibilidad.png"
import RiesgoImg from "../../static/Imagen/riesgo.png"
import SimplicidadImg from "../../static/Imagen/simplicidad.png"
import TransparenciaImg from "../../static/Imagen/transparencia.png"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    height: "100%",
    paddingTop: 120,
    paddingBottom: 120
  },
  product: {
    height: "100%",
    position: "relative",
    padding: theme.spacing(5, 3),
    cursor: "pointer",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    "&:hover": {
      transform: "scale(1.1)"
    }
  },
  productImage: {
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24
  },
  liStyle: {
    textAlign: "justify"
  }
}))

export default function CFLValues() {
  const classes = useStyles()

  return (
    <Layout>
      <Container className={classes.containerPart}>
        <Box mt="160px">
          <Typography variant="h1" component="h1" align="center" gutterBottom>
            <b> Valores de CFL</b>
          </Typography>
        </Box>
        <Box mt="100px">
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item md={4} xs={4} >
                <Paper className={classes.product} elevation={2}>
                  <div>
                    <img
                      alt="Product"
                      className={classes.productImage}
                      src={TransparenciaImg}
                    />
                    <Typography
                      component="span"
                      display="inline"
                      variant="h4"
                      color="textPrimary"
                    >
                      Transparencia
                    </Typography>
                  </div>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.liStyle}
                  >
                    Creemos que la transferencia es la base del éxito, por ello
                    desglosamos todas las comisiones existentes, los criterios
                    de asignación de las inversiones, los riesgos intrínsecos,
                    así como los mecanismos para la mitigación de los mismos.
                    Tenemos un firme compromiso para que nuestros inversionistas
                    tengan una expectativa clara del retorno de sus inversiones.
                    Por ello las rentabilidades estimadas ya incluyen cualquier
                    tipo de comisión aplicable.
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={4} xs={4} >
                <Paper className={clsx(classes.product)} elevation={1}>
                  <div>
                    <img
                      alt="Product"
                      className={classes.productImage}
                      src={SimplicidadImg}
                    />
                    <Typography
                      component="span"
                      display="inline"
                      variant="h4"
                      color="inherit"
                    >
                      Simplicidad
                    </Typography>
                  </div>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography variant="body2" className={classes.liStyle}>
                    Hemos puesto todo nuestro esfuerzo en hacer sencillo lo que
                    es complejo gracias al desarrollo de nuestro sistema
                    automático de diversificación y de nuestro mercado
                    secundario. Nuestros inversores no tienen que molestarse en
                    construir un portafolio de inversiones diversificado, puesto
                    que CFL lo hace de forma automática. De esta forma, los
                    inversores únicamente tienen que ser consciente de los
                    riesgos intrínsecos de cualquier inversión P2P, por favor
                    para más información vea nuestra sección de riesgos.
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={4} xs={4} >
                <Paper className={classes.product} elevation={1}>
                  <div>
                    <img
                      alt="Product"
                      className={classes.productImage}
                      src={EstabilidadImg}
                    />
                    <Typography
                      component="span"
                      display="inline"
                      variant="h4"
                      color="textPrimary"
                    >
                      Estabilidad
                    </Typography>
                  </div>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.liStyle}
                  >
                    Aunque cualquier inversión está sujeta a riesgos, los
                    retornos esperados de nuestros productos han sido diseñados
                    para maximizar la probabilidades de éxito, y así hacer todo
                    lo posible para conseguir las rentabilidades esperadas,
                    aunque esto suponga una disminución del margen operativo de
                    CFL.
                  </Typography>
                </Paper>
              </Grid>
              <br /> <br /> <br />
              <Grid item md={2} xs={2}></Grid>
              <Grid item md={4} xs={4}>
                <Paper className={clsx(classes.product)} elevation={1}>
                  <div>
                    <img
                      alt="Product"
                      className={classes.productImage}
                      src={FlexibilidadImg}
                    />
                    <Typography
                      component="span"
                      display="inline"
                      variant="h4"
                      color="inherit"
                    >
                      Flexibilidad
                    </Typography>
                  </div>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography variant="body2" className={classes.liStyle}>
                    CFL ha desarrollado un sistema secundario propio,
                    permitiendo así la posibilidad a nuestros inversores de
                    retirar sus fondos con anterioridad al término de sus
                    financiamientos.Este mercado secundario se basa en un
                    sistema de colas por orden de llegada. Téngase en
                    consideración que la flexibilidad ofrecida está subordinada
                    a la liquidez del mercado secundario.
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={4} xs={4}>
                <Paper className={clsx(classes.product)} elevation={1}>
                  <div>
                    <img
                      alt="Product"
                      className={classes.productImage}
                      src={RiesgoImg}
                    />
                    <Typography
                      component="span"
                      display="inline"
                      variant="h4"
                      color="inherit"
                    >
                      Gestión de Riesgo
                    </Typography>
                  </div>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography variant="body2" className={classes.liStyle}>
                    Todos nuestros inversores tiene una exposición de riesgo
                    similar. Por favor, para mayor detalle vea nuestra sección
                    de diversificación por scoring crediticio. Las diferencias
                    de los retornos esperados de nuestros productos se basan en
                    la flexibilidad ofrecida en los mismos. Todos los préstamos
                    financiados a través de nuestra plataforma tienen garantía
                    hipotecaria.
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={2} xs={2}></Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
    </Layout>
  )
}
